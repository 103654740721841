<template>
    <div class="portal_pages">
        <div class="result_wpr new">
            <div class="actions mt-2 mb-2">
                <h3 class="section_title">
                    My {{title}}
                    <div class="categories_list" @click="filterList = !filterList" v-click-outside="closeFilters">
                        {{params.status}}<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                            <ul>
                                <li @click="filterForm('due')">Due</li>
                                <li @click="filterForm('completed')">Completed</li>
                                <li @click="filterForm('signed')">Signed</li>
                            </ul>
                        </div>
                    </div>
                </h3>
                <ul>
                    <!-- <li class="filter_btn">
                        <div class="btn_item pointer" :class="params.status === 'due' ? 'active' : ''" @click="filterForm('due')">Due</div>
                        <div class="btn_item pointer" :class="params.status === 'completed' ? 'active' : ''" @click="filterForm('completed')">Completed</div>
                        <div class="btn_item pointer" :class="params.status === 'signed' ? 'active' : ''" @click="filterForm('signed')">Signed</div>
                    </li> -->
                    <li class="optionDrops search_area" :class="{'active': searchField}">
                        <input type="text" placeholder="Search" :disabled="assetsLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                        <button class="search_btn" @click="searchField = !searchField;">
                            <i class="fas fa-search"></i>
                        </button>
                    </li>
                    <!-- <li class="optionDrops contacts-tabs" @click="filterList = !filterList" v-click-outside="closeFilters">
                        {{params.status}}<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                            <ul>
                                <li @click="filterForm('due')">Due</li>
                                <li @click="filterForm('completed')">Completed</li>
                                <li @click="filterForm('signed')">Signed</li>
                            </ul>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
        <div v-if="forms.total" class="list_container">
            <ul class="client_productList">
                <li v-for="(form, f) of forms.data" :key="f">
                    <div class="product_card">
                        <img v-if="form.dashboard_thumb" :src="form.dashboard_thumb" alt="" class="product_thumb">
                        <img v-else src="@/assets/images/thumb/default-form.svg" alt="" class="product_thumb">
                        <div class="product_cont">
                            <h4 class="pointer">{{ form.title }}</h4>
                            <p>{{ form.form_description && form.form_description.length > 150 ? form.form_description.substr(0, 150)+'...' : form.form_description }}</p>
                            <div class="action-buttons">
                                <a href="javascript:void(0);" class="primary_btn custom_element">Quick View</a>
                                <a href="javascript:void(0);" class="primary_btn custom_element">Open Live</a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="pagination mt-2">
                <pagination v-model="params.page" :pages="forms.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
        <div class="empty_section" v-else>
            <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
            <h1 class="text-center">
                Forms, forms, where are you? It seems you haven't been assigned any forms just yet. Don't worry, your coach will post here if any forms are due!
            </h1>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Client Portal Forms',

        data () {
            return {
                searchField: false,
                params: {
                    per_page: 8,
                    page: 1,
                    status: 'due',
                    search: '',
                },
                isTyping: false,
                filterList: false,
            };
        },

        props: {
            title: String,
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getForms(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getForms(vm.params);
                        }
                    }
                }
            },
        },

        computed: mapState({
            forms: state => state.clientPortalModule.forms,
            assetsLoader: state => state.clientPortalModule.assetsLoader,
        }),

        mounted () {
            const vm = this;

            if (!vm.forms.total) {
                vm.getForms(vm.params);
            } else {
                vm.params.page = vm.forms.current_page;
            }
        },

        methods: {
            ...mapActions({
                getForms: 'clientPortalModule/getForms',
            }),

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getForms(vm.params);
            },

            filterForm (status) {
                const vm = this;

                vm.params.page = 1;
                vm.params.status = status;
                // vm.getForms(vm.params);
            },

            closeFilters () {
                const vm = this;

                vm.filterList = false;
            },
        },
    }
</script>

<style scoped>
    h3.section_title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #121525;
        margin: 0;
        align-items: center;
    }
    .portal_pages{
        margin-bottom: 40px;
    }
    .portal_pages .client_productList {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 20px -10px;
    }

    .portal_pages .client_productList li {
        flex: 0 0 25%;
        padding: 15px 10px;
    }

    .portal_pages .client_productList .product_card {
        width: 100%;
    }

    .client_productList .product_card .product_cont p{
        margin-bottom: 45px;
    }

    .inner_pages .actions .filter_btn {
        padding: 0;
        display: flex;
        gap: 30px;
    }

    .inner_pages .actions .filter_btn .btn_item {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    .inner_pages .actions .filter_btn .btn_item.active {
        color: #121525;
        font-weight: 500;
    }
    .empty_section {
        /* border: 1px solid #D9D9D9; */
        border-radius: 10px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        margin: 20px 0;
    }
    .empty_section img{
        max-height: 90px;
        width: auto;
        margin-bottom: 30px;
    }
    .empty_section h1 {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        color: #2c3e50;
        /* font-style: italic; */
    }
    .result_wpr.new .actions > ul{
        gap: 0;
        height: auto;
    }
    .result_wpr.new .actions > ul li.search_area{
        background: #fff;
        height: 35px;
        margin-left: auto;
    }
    .result_wpr.new .actions > ul li.search_area input[type=text]{
        font-size: 11px !important;
    }
    .result_wpr.new .actions > ul li.search_area.active input[type=text]{
        width: 250px;
        padding: 0 15px;
    }
    .action-buttons{
        width: calc(100% + 10px);
        display: flex;
        margin: auto -5px 0;
    }
    .categories_list{
        padding: 5px 15px;
        border-radius: 15px;
        background: #e9e9e9;
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        position: relative;
        min-width: 100px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .categories_list i{
        margin-left: auto;
        padding-left: 10px;

    }
    .categories_list .dropdown_wpr{
        min-width: 170px;
    }
    .categories_list .dropdown_wpr::before{
        display: none;
    }
    @media(max-width: 1199px){
        .result_wpr.new .actions > ul{
            height: 51px;
        }
        .portal_pages .client_productList .product_card .product_cont h4{
            font-size: 14px;
            line-height: 19px;
        }
        .portal_pages .client_productList .product_card .product_cont p{
            font-size: 12px;
            line-height: 16px;
        }
        .inner_pages .actions .filter_btn{
            gap: 20px;
        }
        .inner_pages .actions .filter_btn .btn_item{
            font-size: 13px;
        }
        .portal_pages .client_productList li{
            flex: 0 0 33.333%;
        }
    }
    @media(max-width: 768px){
        .portal_pages .client_productList{
            padding: 0;
        }
        .portal_pages .client_productList li{
            flex: 0 0 50%;
        }
        .result_wpr.new .actions > ul li.search_area{
            border-radius: 17px !important;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            width: 200px;
        }
    }
    @media(max-width: 650px){
        h3.section_title{
            width: 100%;
            margin-bottom: 20px;
        }
        .result_wpr.new .actions > ul,
        .result_wpr.new .actions > ul li.search_area,
        .result_wpr.new .actions > ul li.search_area input{
            width: 100%;
        }
        .client_productList .product_card .product_cont .primary_btn{
            font-size: 12px;
        }
    }
    @media(max-width: 499px){
        .result_wpr.new .actions{
            margin-bottom: 15px;
        }
        .inner_pages .actions .filter_btn{
            gap: 20px;
        }
        .inner_pages .actions .filter_btn .btn_item{
            font-size: 13px;
            line-height: 18px;
        }
        .list_container{
            max-width: 300px;
            margin: 0 auto;
        }
        .portal_pages .client_productList li{
            flex: 0 0 100%;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            width: 100%;
            justify-content: flex-start;
        }
        .empty_section h1{
            font-size: 13px;
            line-height: 23px;
        }
    }
</style>
